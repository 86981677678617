import React, { FC } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

interface TerminalLinkProps {
  to: string;
  text: string;
  size?: number;
  margin?: number;
}

type TStyledP = {
  size: number;
};
type TStyledLink = {
  margin: number;
};

const TerminalLink: FC<TerminalLinkProps> = ({
  to,
  text,
  size = 16,
  margin
}) => {
  return (
    <StyledLink margin={margin ? margin : 8} activeClassName="active" to={to}>
      <StyledP size={size}>{text}</StyledP>
    </StyledLink>
  );
};

const StyledLink = styled(Link)<TStyledLink>`
  ${(props: { margin?: number }) => props.margin && "margin: 8px"};
  cursor: crosshair;
  color: lime;
  text-decoration: none;
  &.active > p {
    color: black;
    background-color: lime;
  }
`;

const StyledP = styled.p<TStyledP>`
  font-size: ${(props: { size: number }) => props.size}px;
  padding: 8px;

  :hover {
    color: black;
    background-color: lime;
  }
`;
export default TerminalLink;
