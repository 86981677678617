import React, { ReactNode } from "react";
import Header from "./header/Header";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
    background-image: radial-gradient(rgba(0, 150, 0, 0.75), black 120%);
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 2rem 0;
    color: lime;
    font: 1.3rem Inconsolata, monospace;
    text-shadow: 0 0 5px #c8c8c8;
  }

  .skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000000;
    color: white;
    padding: 8px;
    z-index: 100;
  }

  .skip-link:focus {
    top: 0;
  }

  body::after {

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: repeating-linear-gradient(0deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 1px,
    transparent 1px,
    transparent 2px);
    pointer-events: none;
  }

  ::selection {
    color: black;
    background: lime;
    text-shadow: none;
  }

  pre {
    margin: 0;
  }
`;

export default ({
  children,
  pageContext,
}: {
  children: ReactNode;
  pageContext: any;
}) => {
  if (pageContext.layout === "slas") {
    return (
      <div>
        <Helmet title="SNRN.se">
          <html lang="en" />
          <meta name="description" content="Silly Name Random Number" />
        </Helmet>
        <body>{children}</body>
      </div>
    );
  }

  return (
    <div>
      <GlobalStyle />
      <Helmet title="SNRN.se">
        <html lang="en" />
        <meta name="description" content="Silly Name Random Number" />
      </Helmet>
      <a className="skip-link" href="#maincontent">
        Skip to main
      </a>
      <Header />
      <StyledDiv id="maincontent">{children}</StyledDiv>
    </div>
  );
};

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 85vh;
  margin-left: 2rem;
  margin-top: 2rem;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 16px; /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: lime; /* color of the tracking area */
  }
  ::-webkit-scrollbar-thumb {
    background-color: lime; /* color of the scroll thumb */
    border: 2px solid black; /* creates padding around scroll thumb */
  }
`;
