import React, { FC } from "react";
import styled from "styled-components";
import TerminalLink from "../TerminalLink";
import Logo from "./Logo";

const Header: FC = () => {
  return (
    <StyledHeader>
      <TerminalLink to={"/"} text={"~/"} />
      <TerminalLink to={"/var"} text={"/var"} />
      <TerminalLink to={"/etc"} text={"/etc"} />
      <TerminalLink to={"/dev"} text={"/dev"} />
      <Logo />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  padding-left: 24px;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-bottom: lime double;
  //box-shadow: 0 0 6px #c8c8c8;
  padding-bottom: 24px;
  @media (max-width: 700px) {
    margin-top: 72px;
    padding-bottom: 0;
  }
`;
export default Header;
