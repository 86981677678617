// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-tsx": () => import("./../src/pages/dev.tsx" /* webpackChunkName: "component---src-pages-dev-tsx" */),
  "component---src-pages-etc-tsx": () => import("./../src/pages/etc.tsx" /* webpackChunkName: "component---src-pages-etc-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-odling-tsx": () => import("./../src/pages/odling.tsx" /* webpackChunkName: "component---src-pages-odling-tsx" */),
  "component---src-pages-slas-tsx": () => import("./../src/pages/slas.tsx" /* webpackChunkName: "component---src-pages-slas-tsx" */),
  "component---src-pages-var-tsx": () => import("./../src/pages/var.tsx" /* webpackChunkName: "component---src-pages-var-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

