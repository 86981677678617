import React, { FC } from "react";
import styled from "styled-components";

const Logo: FC = () => {
  const TitleText = String.raw`
 ____  _   _ ____  _   _ 
/ ___|| \ | |  _ \| \ | |
\___ \|  \| | |_) |  \| |
 ___) | |\  |  _ <| |\  |
|____/|_| \_|_| \_\_| \_|
`;

  return <StyledLogo>{TitleText}</StyledLogo>;
};

const StyledLogo = styled.pre`
  display: flex;
  flex: 1;
  position: absolute;
  top: 10px;
  right: 45px;

  margin-top: -40px;
  font: 1.3rem Inconsolata, monospace;
  @media (max-width: 700px) {
    right: 72px;
  }
`;

export default Logo;
